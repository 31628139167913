
export default {
    name: 'ProductAd',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {},
    data() {
        return {
            ad: {
                visible: false,
                list: []
            }
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        this.getAdAjax()
    },
    methods: {
        getAdAjax() {
            this.$api.common.getAd({
                places: 3
            }).then(response => {
                const res = response.result || [];
                this.ad.list = [...res]
                this.ad.visible = !!this.ad.list.length
            })
        },
        handleAd(obj) {
            window.location.href = obj.jumpUrl
        }
    }
}
