
import ProductCardSku from './ProductCardSku.vue'

export default {
    name: 'ProductCard',
    components: {
        ProductCardSku
    },
    asyncData({api, cookies, ...context}) {},
    props: {
        currency: Object,
        info: Object,
        addSuccess: Object,
        middleEmit: Object
    },
    data() {
        return {
            clear: '',
            card: {
                visible: false,
                title: '',
                list: [],
            },
            select: {
                previewImg: '',
                hasSelectProduct: false,
                hasSelectSku: false,
                hasCardSku: false,
                product: {},
                sku: {},
                all: {}
            },
            params: {
                add: {}
            }
        }
    },
    computed: {},
    watch: {
        info: {
            handler(n, o) {
                this.info.spuId && this.info.goodsType != 7 && this.getCardAjax()
            },
            deep: true,
            immediate: true,
        },
        addSuccess: {
            handler(n, o) {
                this.setAdd()
            },
            deep: true
        }
    },
    created() {},
    mounted() {},
    methods: {
        // 获取贺卡商品
        getCardAjax() {
            this.$api.product.getCardProduct({
                scene: 'detail',
                spuId: this.info.spuId
            }).then(response => {
                const res = response.result || {};
                this.card.title = res.title
                this.card.list = res.items || []
                this.card.visible = !!this.card.list.length
                this.setCardData()
            })
        },
        setCardData() {
            this.card.list.forEach(p => {
                // 图片
                const firstPics = p.pics[0] || {};
                p.previewImg = firstPics.url
                p.displayMarketPrice = p.displaySalePrice
                if(p.skus.length < 2) {
                    const firstSku = p.skus[0] || {};
                    p.previewImg = firstSku.skuPic
                    p.displayMarketPrice = firstSku.displayMarketPrice
                }
            })
        },
        // 加购
        setAddAjax() { // 加购接口
            this.$api.product.setAddBag({
                data: JSON.stringify(this.params.add)
            }).then(response => {
                this.$updateBagQuantity()
            })
        },
        setAdd() {
            const select = this.select.sku;
            this.params.add = [{
                skuId: select.id,
                spuId: select.spuId,
                quantity: 1,
                customizeType: 1,
                isVipService: false
            }]
            this.addSuccess?.main_cart_id && this.select.hasCardSku && this.setAddAjax()
        },
        // 预览图
        handleImg(obj) {
            if(!obj.hasSelect) {
                this.$ImagePreview({
                    images: [obj.previewImg],
                })
                return
            }
            const picsArr = this.select.all.sku.mergeList || [];
            let pics = [];
            picsArr.forEach(p => {
                pics.push(p.skuPic)
            })
            const i = pics.indexOf(this.select.previewImg);
            const index = i != -1 ? i : 0;
            this.$ImagePreview({
                images: pics,
                startPosition: index
            })
        },
        // 选择
        handleChoose(obj) {
            this.select.hasSelectSku = false
            this.select.hasCardSku = false
            if(this.select.hasSelectProduct && this.select.product.productCode == obj.productCode) {
                this.select.hasSelectProduct = false
            } else {
                this.select.hasSelectProduct = true
            }
            this.select.product = obj
            this.clear && clearTimeout(this.clear)
            this.clear = setTimeout(() => {
                this.select.hasCardSku = this.select.hasSelectProduct
            }, 50)
        },
        // sku change
        changeSkuSelect(obj) {
            this.select.sku = obj.select || {}
            this.select.previewImg = this.select.sku.skuPic
            this.select.hasSelectSku = this.select.sku.hasAllSelect
            this.select.all = obj || {}
            this.middleEmit.fn('cardSelectEmit', {
                ...this.select
            })
        },
        // 跳转
        jumpProduct(obj) {
            this.$router.push({
                name: 'Product-Seo',
                params: {
                    code: obj.productCode,
                    seoUrl: obj.seoUrl
                }
            })
        }
    },
}
