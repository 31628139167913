
export default {
    name: 'ProductActive',
    props: {
        middleEmit: Object,
        info: Object,
        price: Object,
    },
    data() {
        return {
            rsInfo: {},
            active: {
                visible: false,
                hasMore: false,        // 是否显示更多
                hasGift: false,        // 是否显示赠品列表
                hasPrice: false,       // 是否显示金额
                hasActiveBlock: false, // 是否显示活动板块
                className: '',
                activityName: '',
                title: '',             // 活动标题
                tip: '',               // 提示文案
                selectFreeGift: [],    // 赠品列表
                displayDiscount: '',   // 优惠额（金额/件数/折扣率）
                displayThreshold: '',  // 门槛值
                activeTime: {},        // 活动时间
            },
            gift: { // 满赠
                list: []
            }
        }
    },
    computed: {},
    watch: {
        info: {
            handler() {
                this.setData()
            },
            deep: true,
            immediate: true
        }
    },
    created() {},
    mounted() {},
    methods: {
        // 设置数据
        setData() {
            this.rsInfo = JSON.parse(JSON.stringify(this.info))
            // activityType -> 1 金额 2 件数
            // goodsType -> 0 下架商品 1 普通商品 2 满赠商品 3 赠品 4 闪购商品 5 满折商品 8 满减 9 单品折扣
            this.active = {
                ...this.active,
                ...this.rsInfo.activityInfo
            }
            this.active.visible = [2, 4, 5, 8, 9].includes(+this.rsInfo.goodsType)
            this.active.hasActiveBlock = this.active.visible
            this.active.endsIn =  this.active.endsIn
            this.active.selectFreeGift = this.active.selectFreeGift || []
            this.active.hasPrice = [4, 5, 8].includes(+this.rsInfo.goodsType)
            if(this.active.conditions) {
                this.active.displayDiscount = this.active.conditions[0].displayDiscount || this.active.conditions[0].discount
                this.active.displayThreshold = this.active.conditions[0].displayThreshold || this.active.conditions[0].threshold
            }
            // 设置折扣
            const lang = this.$storage.get('language') || {};
            this.rsInfo.rsDiscount = false ? `${this.info.discountRate} ${this.$translate('OFF')}` : `-${this.info.discountRate}%`
            // 设置活动标题
            this.setActiveTitle()
            // 设置活动区间时间
            this.setActiveTime()
            // 设置背景色
            switch(+this.rsInfo.goodsType) {
                case 2:
                    this.setFreeGiftData()
                break;
                case 4:
                    this.setFlashSaleData()
                break;
                case 5:
                    this.setFullFoldData()
                break;
                case 8:
                    this.setFullReduceData()
                break;
                case 9:
                    this.setUnitDiscountData()
                break;
                default:
            }
            this.middleEmit.fn('setActiveData', this.active)
        },
        // 跳转满赠页
        handleGiftMore() {
            this.middleEmit.fn('giftMore')
        },
        // 设置满赠数据
        setFreeGiftData() {
            this.active.className = 'ProductActive-section--white'
            this.active.hasGift = this.active.selectFreeGift.length
            this.active.hasGift > 5 && (this.active.hasMore = true)
            this.active.tip = this.active.title
        },
        // 设置闪购数据
        setFlashSaleData() {
            this.active.className = 'ProductActive-section--red'
            this.active.tip = this.active.tags
            this.active.activityName = this.active.tags
            this.active.title = this.active.activityName
        },
        // 设置满折数据
        setFullFoldData() {
            this.active.className = 'ProductActive-section--orange'
            this.active.tip = this.active.activityName
        },
        // 设置满减数据
        setFullReduceData() {
            this.active.className = 'ProductActive-section--orange'
            this.active.tip = this.active.title
        },
        // 单品折扣
        setUnitDiscountData() {
            this.active.className = 'ProductActive-section--orange'
            this.active.tip = this.active.title
            this.active.hasActiveBlock = false
            this.active.activityCustomName = this.$translate('Limited Deals')
        },
        // 设置活动标题
        setActiveTitle() {
            if(this.active.conditions) {
                this.active.conditions.some((item, i) => {
                    if(!i) {
                        this.active.title = item.activityCopy
                        return true
                    }
                })
            }
        },
        // 活动区间时间
        setActiveTime() {
            let start = this.$dayjs(this.active.activityStartDate || 0).format('YYYY/MM/DD');
            let end = this.$dayjs(this.active.activityEndDate).format('YYYY/MM/DD');
            this.active.activeTime.start = start
            this.active.activeTime.end = end
            this.active.activeTime.merge = `${start.time}-${end.time}`
        },
        // 倒计时结束
        finishTime() {
            this.middleEmit.fn('getDetailAjax')
        },
        // 跳转商品
        jumpProduct(obj) {
            this.$router.push(`/${obj.seoUrl}`)
        },
    },
}
