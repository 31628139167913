
export default {
    name: 'ProductAddSuccess',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        query: Object,
        middleEmit: Object,
        info: Object,
        skuSelect: Object,
        currency: Object,
    },
    data() {
        return {
            card: { // 贺卡推荐
                product: [],
                list: []
            },
            rec: { // 商品推荐
                visible: false,
                length: 0,
                list: []
            },
            cart: { // 购物车
                list: [],
                placeProductInfo: {},
                addLog: {}
            },
            popup: {
                visible: false
            },
            rsPopup: {
                visible: false,
                type:2, //弹框类型
            },
            bagCompo: {              // 加购组件
                type: 1,             // 1 加购 2 定制
                info: {},
                skuSelect: {},
                options: {
                    visible: false,
                    hasImdAddBag: false,
                    hasRequest: false,
                    btnText: ''
                },
            },
            customizeCompo: {        // 定制组件
                visible: false,
                hasPopup: true,
                url: '',
                on: {}
            },
            params: {                // 加购参数
                quantity: 1,         // 加购数量
                main_cart_id: '',    // 加购id
                add: [],             // 加购参数
                placeProduct: [],    // 下单商品
                addLog: [],          // 加购/结算参数
            },
            bagQuantity:'0',
            customizeUrl:''
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {
        // 获取推荐产品------------------------------------
        getBagAjax() { // 获取购物车
            return new Promise((resolve, reject) => {
                this.$api.bag.getBag({
                    id: '',
                    onSelect: false
                }).then(response => {
                    const res = response.result || {};
                    this.cart.list = res.shopCart || []
                    if(response.code == 'success') {
                        resolve(res)
                    }
                })
            })
        },
        getCardListAjax() { // 获取贺卡推荐接口
            this.$api.common.getRecCard({
                spuCode: this.info.productCode,
                index: 2
            }).then(response => {
                const res = response.result || {};
                this.card.product = res.recommendList || []
                this.card.list = res.productList || []
                this.setData()
                this.rsPopup.visible = true
            })
        },
        setData() { // 设置商品数据
            let length = 0;
            this.rec.list = [this.card.list, this.card.product]
            this.rec.list.forEach(c => {
                c.forEach((item, i) => {
                    length += 1
                    // 评分
                    item.resCoreAverage = item.scoreAverage / 10000
                    if(item.goodsType == 7) {
                        item.activityLabel = [{
                            status: true,
                            tagText: this.lang.translate('Greeting Card')
                        }]
                    }
                    // 标签处理
                    let reProductLabel = []
                    !item.productLabel && (item.productLabel = [])
                    item.productLabel.forEach(list => {
                        reProductLabel.push({
                            ...list,
                            style: `color: ${list.color};background: ${list.backgroundColor};`
                        })
                    })
                    if(item.discountRate) {
                        reProductLabel.push({
                            name: `-${item.discountRate}%`,
                            style: `color:#fff;background: #F8981C;`
                        })
                    }
                    item.reProductLabel = reProductLabel[0] && [reProductLabel[0]] || []
                })
            })
            this.rec.length = length
            this.rec.visible = !!this.rec.length
        },
        // 商品操作
        handleSet(obj) {
            this.bagCompo.info = obj
            this.bagCompo.options.visible = true
            this.params.quantity = 1
            if(obj.customizeType != 2) {
                // 加购
                this.bagCompo.type = 1
                this.bagCompo.options.btnText = this.$translate('ADD TO BAG')
            } else {
                // 定制
                this.bagCompo.type = 2
                this.bagCompo.options.btnText = this.$translate('Customize Now')
            }
        },
        // 加购--------------------------------------
        getAddParams() { // 获取参数
            const info = this.bagCompo.info || {};
            const skuSelect = this.bagCompo.skuSelect || {};
            let addObj = {
                skuId: skuSelect.id,
                spuId: skuSelect.spuId,
                quantity: this.params.quantity || 1,
                customizeType: info.customizeType,
            };
            // 加购参数
            this.params.add = [addObj]
            // 加购日志参数
            this.params.addLog = [addObj]
        },
        setAddAjax() { // 加购接口
            this.$api.product.setAddBag({
                data: JSON.stringify(this.params.add)
            }).then(response => {
                const res = response.result || [];
                if(response.code == 'success') {
                    const cartId = res.cartId || [];
                    this.params.main_cart_id = cartId[0] || ''
                    this.bagCompo.options.visible = false
                    this.$updateBagQuantity()
                }
            })
        },
        setAddBag() { // 加入购物车
            this.getAddParams()
            this.setAddAjax()
            // 加购日志
            this.setAddLog()
            // 加购埋点
            try {
                this.setBuried()
            } catch {}
        },
        emitSkuConfirm(obj) { // 加购弹窗
            this.bagCompo.skuSelect = obj.sku.select || {}
            this.params.quantity = obj.options?.quantity || 1
            if(this.bagCompo.skuSelect.hasAllSelect) {
                if(this.bagCompo.type != 2) {
                    this.setAddBag()
                } else {
                    this.setCustomize()
                }
            }
        },
        // 定制---------------------------------------
        setCustomize() {
            const info = this.bagCompo.info || {};
            const skuSelect = this.bagCompo.skuSelect || {};

            let queryArr = [];
            let queryStr = '';
            let query = {
                spu: skuSelect.spuId,
                sku: skuSelect.id,
                productCode: info.productCode,
            };
            Object.keys(query).forEach(key => {
                queryArr.push(`${key}=${query[key]}`)
            })
            queryStr = queryArr.join('&')
            this.customizeCompo.visible = true
            this.customizeCompo.url = `${location.origin}/custonIndex?${queryStr}`
            this.customizeCompo.on.setCustomizeDone = this.setCustomizeDone
            this.bagCompo.options.visible = false
        },
        setCustomizeDone(obj = {}) { // 定制完成
            this.params.main_cart_id = obj.mainCartId || ''
            this.customizeCompo.visible = false
            this.$updateBagQuantity()
        },
        // 下单 checkout------------------------------------
        setParamsData() { // checkout-购物车参数
            const key = [this.params.main_cart_id];
            let placeCartArr = [];
            let placeProductArr = [];
            let addLog = [];
            this.cart.list.forEach(p => {
                p.cartItems.forEach(c => {
                    if(key.includes(c.id)) {
                        placeCartArr.push(c)
                    }
                })
            })
            // 下单商品
            placeCartArr.forEach(p => {
                // 下单
                if(p.goodsType && p.onSelect) {
                    placeProductArr.push(p)
                    // 贺卡
                    if(p.greetingCardItems) {
                        placeProductArr.push(...p.greetingCardItems)
                    }
                    // 结算日志参数
                    addLog.push({
                        skuId: p.skuId,
                        spuId: p.spuId,
                        quantity: p.quantity || 1,
                        customizeType: p.customizeType,
                    })
                }
            })
            // 下单参数
            this.params.placeProduct = placeProductArr
            // 结算日志参数
            this.params.addLog = addLog
        },
        handleCheck() { // checkout-下单按钮
            this.getBagAjax().then(res => {
                this.popup.visible = false
                this.rsPopup.visible = false
                this.setParamsData()
                this.setPlaceOrderStorage()
                this.setAddLog(2)
                this.setAddress()
            })
        },
        setPlaceOrderStorage() { // checkout-下单缓存
            this.$storage.set('order/placeProduct', this.params.placeProduct)
            this.$storage.set('order/placePage', location.href)
        },
        setAddress() { // checkout-下单地址校验
            const orderAddress = this.$storage.get('order/placeAddress');
            if(!orderAddress) {
                this.$fnApi.debounce(() => {
                    this.$router.push({
                        name: 'Address',
                        query: {
                            key: 'order/placeAddress',
                            from: 'afterAddCart'
                        }
                    })
                }, 10)
            } else {
                this.$router.push({
                    name: 'OrderConfirm',
                    query: {
                        from: 'afterAddCart'
                    }
                })
            }
        },
        // 其他操作-------------------------------------
        closeBtn(){
            this.popup.visible = false
            this.rsPopup.visible = false
        },
        handleKeep() { // 继续购物
            this.popup.visible = false
            this.rsPopup.visible = false
            this.$router.push({
                name: 'Bag',
                query: {
                    from: 'product'
                }
            })
        },
        showPopup(visible, obj = {}) { // 弹窗显示
            this.popup.visible = visible
            this.params.main_cart_id = obj.main_cart_id
            this.customizeUrl = obj.customUrl;
            this.$api.bag.getUserBagQuantity().then(response => {
                let res = response.result || {};
                this.bagQuantity = res.count || ''
            })
            if(obj.type==4){
                this.rsPopup.visible = true
                this.rsPopup.type = 4
            }else if(obj.type==5){
                this.rsPopup.visible = true
                this.rsPopup.type = 5
            }else if(this.popup.visible) {
                this.rsPopup.type = 2
                this.getCardListAjax()
            }
        },
        close() { // 关闭
            this.popup.visible = false
        },
        jumpLink(obj) { // 跳转
            this.$router.push({
                name: 'Product-Seo',
                params: {
                    code: obj.productCode,
                    seoUrl: obj.productName
                },
            })
        },
        // 加购/结算日志-------------------------
        setAddLogAjax(log) { // 加购结算日志接口
            this.$api.product.setAddBagLog({
                ...log
            }).then(response => {})
        },
        setAddLog(traceType = 1) {
            const info = this.bagCompo.info || {};
            let log = {
                addPage: 17,
                traceType,
                spuItems: this.params.addLog,
                settleSource: info.id,
            };
            this.setAddLogAjax(log)
        },
        // 加购埋点
        setSymbolReplace(val) {
            try {
                return val && val.includes(',') ? val.replaceAll('.', '').replaceAll(',', '.').replace(/[^\d.-]/g, '') : val
            } catch {
                return val
            }
        },
        setBuried() {
            const select = this.bagCompo.skuSelect || {};
            const info = this.bagCompo.info
            // tiktok
            this.$fnApi.buried.tiktok({
                type: 'ClickButton',
                params: {
                    content_type: 'product',
                    content_id: info.productCode,
                    currency: 'USD',
                }
            })
            this.$fnApi.buried.tiktok({
                type: 'AddToCart',
                params: {
                    content_type: 'product',
                    quantity: this.params.quantity,
                    content_id: info.productCode,
                    currency: this.currency.code,
                    price: select.displayMarketPrice,
                    value: this.setSymbolReplace(select.displayMarketPrice)
                }
            })
            // gtag 加购事件埋点
            this.$fnApi.buried.gtag({
                type: 'conversion_cart',
                params: {
                    'value': this.setSymbolReplace(select.displayMarketPrice),
                    'currency': this.currency.code
                }
            })
            this.$fnApi.buried.gtag({
                type: 'add_to_cart',
                params: {
                    items: [{
                        id: info.productCode,
                        name: info.productName,
                        quantity: this.params.quantity,
                        price: this.setSymbolReplace(select.displayMarketPrice)
                    }]
                }
            })
            // criteo 埋点
            // this.$fnApi.buried.criteo({
            //     type: 'addToCart',
            //     params: [{
            //         id: info.productCode,
            //         price: this.setSymbolReplace(select.displayMarketPrice),
            //         quantity: this.params.quantity,
            //     }]
            // })
            // fbq 埋点
            this.$fnApi.buried.fbq({
                type: 'AddToCart',
                params: {
                    content_type: 'product',
                    content_ids: info.productCode,
                    currency: 'USD',
                    value: this.setSymbolReplace((select.salePrice/100).toFixed(2)),
                    contents: [{
                        id: info.productCode,
                        quantity: this.params.quantity,
                    }]
                }
            }, this)
        },
    }
}
