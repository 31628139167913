import { render, staticRenderFns } from "./ProductBundle.vue?vue&type=template&id=a08e1538&scoped=true&"
import script from "./ProductBundle.vue?vue&type=script&lang=js&"
export * from "./ProductBundle.vue?vue&type=script&lang=js&"
import style0 from "./ProductBundle.vue?vue&type=style&index=0&id=a08e1538&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a08e1538",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {YfnIcon: require('/home/php1/m-nuxt/components/YfnIcon.vue').default,YfnImage: require('/home/php1/m-nuxt/components/YfnImage.vue').default,YfnSwiper: require('/home/php1/m-nuxt/components/YfnSwiper.vue').default,YfnProduct: require('/home/php1/m-nuxt/components/YfnProduct.vue').default,YfnButton: require('/home/php1/m-nuxt/components/YfnButton.vue').default,YfnPopup: require('/home/php1/m-nuxt/components/YfnPopup.vue').default,YfnBag: require('/home/php1/m-nuxt/components/YfnBag.vue').default,CustomizeIframe: require('/home/php1/m-nuxt/components/CustomizeIframe.vue').default})
