
export default {
    name: 'ProductActivePopup',
    props: {
        middleEmit: Object,
        info: Object,
        active: Object,
        popup: Object,
    },
    data() {
        return {}
    },
    methods: {
        // 活动跳转
        jumpActivity(val) {
            console.log(this.active)
            // val -> 1 满赠 2 满减 3 满折 4 闪购
            const name = `Activity-${['FreeGifts', 'FullReduce', 'FullFold', 'FlashSale'][val-1]}`;
            this.$router.push({
                path:'/activity/'+this.active.seoUrl
                // name,
                // query: {
                //     activityId: this.active.activityId
                // }
            })
        },
        // 倒计时结束
        finishTime() {
            this.middleEmit.fn('getDetailAjax')
        }
    },
}
